import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _11673662 = () => interopDefault(import('../src/pages/baggage-details/index.vue' /* webpackChunkName: "pages/baggage-details/index" */))
const _1118823e = () => interopDefault(import('../src/pages/booking-products/index.vue' /* webpackChunkName: "pages/booking-products/index" */))
const _95a6336a = () => interopDefault(import('../src/pages/callback.vue' /* webpackChunkName: "pages/callback" */))
const _2278a098 = () => interopDefault(import('../src/pages/downloads/index.vue' /* webpackChunkName: "pages/downloads/index" */))
const _0813f419 = () => interopDefault(import('../src/pages/guests-details/index.vue' /* webpackChunkName: "pages/guests-details/index" */))
const _4d1b7e53 = () => interopDefault(import('../src/pages/login.vue' /* webpackChunkName: "pages/login" */))
const _0bf9377d = () => interopDefault(import('../src/pages/maintenance.vue' /* webpackChunkName: "pages/maintenance" */))
const _4e110aba = () => interopDefault(import('../src/pages/manage-booking/index.vue' /* webpackChunkName: "pages/manage-booking/index" */))
const _5243378e = () => interopDefault(import('../src/pages/my-profile.vue' /* webpackChunkName: "pages/my-profile" */))
const _7d7c353a = () => interopDefault(import('../src/pages/search-booking.vue' /* webpackChunkName: "pages/search-booking" */))
const _663ebece = () => interopDefault(import('../src/pages/downloads/error.vue' /* webpackChunkName: "pages/downloads/error" */))
const _7f3eff36 = () => interopDefault(import('../src/pages/downloads/ticket.vue' /* webpackChunkName: "pages/downloads/ticket" */))
const _92602d2c = () => interopDefault(import('../src/pages/guests-details/questionnaire.vue' /* webpackChunkName: "pages/guests-details/questionnaire" */))
const _1bc3e2c5 = () => interopDefault(import('../src/pages/manage-booking/cancel/index.vue' /* webpackChunkName: "pages/manage-booking/cancel/index" */))
const _5fe38a86 = () => interopDefault(import('../src/pages/manage-booking/update-details.vue' /* webpackChunkName: "pages/manage-booking/update-details" */))
const _bb25432a = () => interopDefault(import('../src/pages/manage-booking/cancel/receipt.vue' /* webpackChunkName: "pages/manage-booking/cancel/receipt" */))
const _f9184988 = () => interopDefault(import('../src/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/baggage-details",
    component: _11673662,
    name: "baggage-details"
  }, {
    path: "/booking-products",
    component: _1118823e,
    name: "booking-products"
  }, {
    path: "/callback",
    component: _95a6336a,
    name: "callback"
  }, {
    path: "/downloads",
    component: _2278a098,
    name: "downloads"
  }, {
    path: "/guests-details",
    component: _0813f419,
    name: "guests-details"
  }, {
    path: "/login",
    component: _4d1b7e53,
    name: "login"
  }, {
    path: "/maintenance",
    component: _0bf9377d,
    name: "maintenance"
  }, {
    path: "/manage-booking",
    component: _4e110aba,
    name: "manage-booking"
  }, {
    path: "/my-profile",
    component: _5243378e,
    name: "my-profile"
  }, {
    path: "/search-booking",
    component: _7d7c353a,
    name: "search-booking"
  }, {
    path: "/downloads/error",
    component: _663ebece,
    name: "downloads-error"
  }, {
    path: "/downloads/ticket",
    component: _7f3eff36,
    name: "downloads-ticket"
  }, {
    path: "/guests-details/questionnaire",
    component: _92602d2c,
    name: "guests-details-questionnaire"
  }, {
    path: "/manage-booking/cancel",
    component: _1bc3e2c5,
    name: "manage-booking-cancel"
  }, {
    path: "/manage-booking/update-details",
    component: _5fe38a86,
    name: "manage-booking-update-details"
  }, {
    path: "/manage-booking/cancel/receipt",
    component: _bb25432a,
    name: "manage-booking-cancel-receipt"
  }, {
    path: "/",
    component: _f9184988,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
